<template src="@/../../../templates/brand/components/templates/user/ProgramOrders.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import countDown from '@/components/Countdown.vue'
import accounting from 'accounting'

// logic
export default {
  // component lifecycle events
  created () {
    this.getPrograms().then(() => {
      this.render = true
    })
    this.getPreorderProducts()
  },
  components: { countDown },
  data () {
    return {
      render: false
    }
  },
  computed: {
    ...mapState({
      programs: ({ Program }) => Program.programs,
      preorders: ({ App }) => App.user.profile.preorders,
      current: ({ Program }) => {
        if (!Program.programs) return {}
        const _now = new Date()
        const _current = _.map(
          i =>
            new Date(i.starts) < _now && new Date(i.ends) > _now
              ? { id: i._id, ends: new Date(i.ends).getTime() }
              : false,
          Program.programs
        )
        if (_current.length > 1) {
          return (
            _.find(p => p._id === _.find(c => c.ends === _.min(_.map(i => i.ends, _current)), _current).id, Program.programs) || {}
          )
        } else {
          return _.find(i => i._id === _current[0].id, Program.programs) || {}
        }
      },
      orderproducts: ({ Product }) => Product.preorderProducts,
      user: ({ App }) => App.user,
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    products () {
      return this.orderproducts.filter(i => this.preorders.items[i.SKU])
    }
  },
  methods: {
    ...mapActions([
      'getProgram',
      'getPrograms',
      'getPreorderProducts',
      'programSummaryUpdate',
      'profileUpdate'
    ]),
    getUrl (item) {
      let _url = `/programs/${this.current.url}/product/${item.familyKey}`
      if (item.variationKey && item.relatedBy !== 'variation')
        _url += `/${item.variationKey}`
      return _url
    },
    adjustedPrice (item) {
      const _t = item.adjustments
      const _prog = _.find(
        i => i.url === this.preorders.details[item.SKU].program,
        this.programs
      )
      let _tiers = []
      for (let i = 0, c = _t.length; i < c; i++) {
        if (i === 0)
          _tiers.push({
            min: 0,
            max: _t[i + 1].volume,
            range: val => val >= 0 && val <= _t[i + 1].volume,
            value: _t[i].value
          })
        else if (i === _t.length - 1)
          _tiers.push({
            min: _t[i].volume,
            max: _t[i].volume + 1,
            range: val => val > _t[i].volume,
            value: _t[i].value
          })
        else
          _tiers.push({
            min: _t[i].volume,
            max: _t[i + 1].volume,
            range: val => val >= _t[i].volume && val <= _t[i + 1].volume,
            value: _t[i].value
          })
      }
      let _tier = _tiers.find(i => i.range(_prog.summary[item.SKU].volume))
      // let _tier = _.map((i) => { if(i.range(_prog.summary[item.SKU].volume)) return i }, _tiers)[0];
      let _custom = this.preorders.details[item.SKU].custom
        ? this.preorders.items[item.SKU] * 3
        : 0
      return {
        unit: _tier.value,
        volume: this.preorders.items[item.SKU],
        custom: _custom,
        subtotal: this.preorders.items[item.SKU] * _tier.value + _custom
      }
    },
    tempTiers () {
      let _t = this.product.adjustments
      let _tiers = []
      for (let i = 0, c = _t.length; i < c; i++) {
        if (i === 0)
          _tiers.push({
            min: 0,
            max: _t[i + 1].volume,
            range: val => val >= 0 && val <= _t[i + 1].volume,
            value: _t[i].value
          })
        else if (i === _t.length - 1)
          _tiers.push({
            min: _t[i].volume,
            max: _t[i].volume + 1,
            range: val => val > _t[i].volume,
            value: _t[i].value
          })
        else
          _tiers.push({
            min: _t[i].volume,
            max: _t[i + 1].volume,
            range: val => val >= _t[i].volume && val <= _t[i + 1].volume,
            value: _t[i].value
          })
      }
      return _tiers
    },
    updateItem (item) {
      const _profile = _.assign({}, this.user.profile)
      const _val = window.$(this.$el)
        .find(':input[data-sku=' + item.SKU + ']')
        .val()
      _profile.preorders.items[item.SKU] = parseInt(_val, 10)
      if (!parseInt(_val)) delete _profile.preorders.items[item.SKU]
      for (const itm in _profile.preorders.items) {
        if (!_profile.preorders.items[itm]) {
          delete _profile.preorders.items[itm]
          delete _profile.preorders.details[itm]
        }
      }

      // change quantity of an item
      this.profileUpdate({ user: this.user, profile: _profile })
      this.programSummaryUpdate({
        program: _.find(
          i => i.url === _profile.preorders.details[item.SKU].program,
          this.programs
        ),
        product: item,
        user: this.user,
        profile: _profile
      })
      this.getPrograms()
    },
    removeItem (item) {
      const _profile = _.assign({}, this.user.profile)
      const _prog = _.find(
        i => i.url === _profile.preorders.details[item.SKU].program,
        this.programs
      )

      delete _profile.preorders.items[item.SKU]
      delete _profile.preorders.details[item.SKU]

      this.profileUpdate({ user: this.user, profile: _profile })
      this.programSummaryUpdate({
        program: _prog,
        product: item,
        user: this.user,
        profile: _profile
      })
      this.getPrograms()
    },
    currencyLocale (val) {
      return accounting.formatMoney(val, this.appconfig.locale[this.$i18n.locale].currency)
    }
  }
}
</script>

<style></style>
